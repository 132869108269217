import "./src/styles/tailwind.css";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      throwOnError: true,
    },
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export function wrapRootElement({ element }) {
  return (
    <QueryClientProvider client={queryClient}>
      {element} <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
